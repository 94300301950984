<template>
    <div id="passlog-list-page" class="data-list-container dark-layout">
        <vx-card>
            <div class="table_height_container">
                <el-row class="search-row" type="flex">
                  <el-col :span="24">
                    <el-select style="width: 150px" class="search-input" size="mini" v-model="selectOption.openType" placeholder="请选择开门方式">
                        <el-option v-for="item in openTypeOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
                    </el-select>
                    <el-input style="width: 150px" class="search-input" size="mini" v-model="selectOption.people_name" placeholder="请输入姓名"></el-input>
                    <el-input style="width: 150px" class="search-input" size="mini" v-model="selectOption.device_code" placeholder="请输入设备编号"></el-input>
                    <el-date-picker class="search-input" size="mini"  v-model="searchTime" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"  format="yyyy 年 MM 月 dd 日" value-format="yyyy-MM-dd">></el-date-picker>
                    <el-button class="search-input" size="mini"  @click="searchAction">搜索</el-button>
                    <el-button size="mini"  @click="resetAction">重置</el-button>
                  </el-col>
                </el-row>
                <!-- 整体表 -->
                <el-table class="table_theme" :data="Arr" ref="rolesTable" row-key="device_code" v-loading="loading">
                    <el-table-column type="index" width="60" label="序号">
                        <template slot-scope="scope">
                            <span>{{(pageOpt.currentPage - 1) * pageOpt.currentPageSize + scope.$index + 1}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column property="peopleName" label="姓名" width="100"  align="center"  show-overflow-tooltip></el-table-column>
                    <el-table-column label="手机号" width="100" align="center"  show-overflow-tooltip>
                        <template slot-scope="scope">
                            <p>{{scope.row.PhoneNo ? scope.row.PhoneNo : "无" }}</p>
                        </template>
                    </el-table-column>
                    <el-table-column label="证件号码" width="150" align="center" show-overflow-tooltip>
                        <template slot-scope="scope">
                            <p>{{scope.row.certificateNo ? scope.row.certificateNo : "无" }}</p>
                        </template>
                    </el-table-column>
                    <el-table-column property="accessLocation" label="通行位置" width="200"  align="center" show-overflow-tooltip></el-table-column>
                    <el-table-column label="开门方式" width="200" align="center"  show-overflow-tooltip>
                        <template slot-scope="scope">
                            <p>{{ _.find(openTypeOptions, { 'value': scope.row.openType}).label }}</p>
                        </template>
                    </el-table-column>
                    <el-table-column property="openTime" label="开门时间"  show-overflow-tooltip></el-table-column>
                </el-table>
                <!-- 分页 -->
                <div class="bottom mt-5 text-center">
                <el-pagination  class="pagination" layout="total, sizes, prev, pager, next"  prev-text="上一页" next-text="下一页"
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="pageOpt.currentPage"
                    :page-sizes="pageOpt.pageSizes"
                    :page-size="pageOpt.currentPageSize"
                    :pager-count="pageOpt.pageCount"
                    :total="pageOpt.totalPageSize"
                    :hide-on-single-page="pageOpt.hideSinglePage"
                ></el-pagination>
                </div>
            </div>
        </vx-card>
    </div>
</template>


<script>
export default {
  data() {
    return {
        village_name: '',
        // 选中数据
        selected: [],
        searchTime:[],
        // 筛选状态
        selectOption: {
            village_code: this.$store.state.auth.space.space_code || "",
            device_code: "",
            openType:"",
            people_name:"",
            startTime: "",
            endTime: "",
            index: 1,
            pagesize: 10
        },
        // 渲染数据
        Arr: [],
        // 加载状态
        height: this.$store.state.windowHeight,
        currentIndex: null,
        drawer: false,
        loading: false,
        drawerLoading: false,
        // 当前页
        pageOpt: {
            currentPage: 1,
            currentPageSize: 10,
            totalPageSize: 0,
            hideSinglePage: true,
            pageSizes: [10, 20, 30],
            pageCount: 5
        },
        // 开门方式
        openTypeOptions: [
            {value: '0',label: '刷卡'}, 
            {value: '1',label: '蓝牙开门'}, 
            {value: '2',label: '人脸开门'}, 
            {value: '3',label: '密钥开门'}, 
            {value: '5',label: '呼叫开门'},
            {value: '6',label: '门内开门'},
            {value: '7',label: '远程开门'},
            {value: '8',label: '访客远程开门'},
            {value: '9',label: '二维码开门'}
        ],
    }
  },
  watch: {
    "$store.state.auth.space.space_code"(val) {
      this.village_name = this.$store.state.auth.space.space_name
      this.selectOption.village_code = val;
      this.searchTime = []
      this.selectOption.device_code = ""
      this.selectOption.openType = ""
      this.selectOption.people_name = ""
      this.getPassLogList();
    },
  },
  methods: {
    // 列表序号
    rowClassName({ row, rowIndex }) {
      row.xh =
        rowIndex +
        1 +
        (this.pageOpt.currentPage - 1) * this.pageOpt.currentPageSize;
    },
    // 调整单页条数
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`);
      this.pageOpt.currentPageSize = val;
      this.getPassLogList()
    },
    // 调整当前页数
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`);
      this.pageOpt.currentPage = val;
      this.getPassLogList()
    },
    // 递归添加 (改动小可以使用递归)
    recurisonMenu(array) {
      return array;
    },
    // 获取通行记录
    async getPassLogList() {
      this.loading = true;
      try {
        let param = this.selectOption
        param["pageIndex"] = this.pageOpt.currentPage,
        param["pageSize"] = this.pageOpt.currentPageSize
        param["startTime"] = this.searchTime ? (this.searchTime.length > 0 ? this.searchTime[0] : ""): ""
        param["endTime"] = this.searchTime ? (this.searchTime.length > 0 ? this.searchTime[1] : "") : ""
        let result = await this.$apis.ODC("获取通行记录", param);
        if (this.$apis.isOk(result)) {
           this.Arr = this.recurisonMenu(result.Data);
           this.pageOpt.totalPageSize = result.Count;
        } else {
          this.$cFun.showNotify(this.$vs.notify, "warning", this.$apis.getResMessage(result));
        }
      } catch (err) {
        this.$cFun.showNotify(this.$vs.notify, "danger", err.message ? err.message : err);
      }
      this.loading = false;
    },
    // 请求接口
    async searchAction(){
      this.pageOpt.currentPage = 1;
      this.getPassLogList();
    },
    // 重置
    resetAction() {
        this.searchTime = []
        this.selectOption.device_code = ""
        this.selectOption.openType = ""
        this.selectOption.people_name = ""
        this.getPassLogList();
    }
  },
  async created() {
    // 请求数据
    this.village_name = this.$store.state.auth.space.space_name
    this.getPassLogList();
  },
  components: {
  }
};
</script>

<style lang="scss" scope="scope">
  #passlog-list-page {
    .search-row {
      margin-bottom: 5px;
      .search-select {
        margin-right: 5px;
      }
      .search-input {
        margin-right: 5px;
      }
      .el-button+.el-button {
        margin-left: 0px;
       }
      .search-btn{
        &:hover {
          background-color: rgba(var(--vs-primary), 0.1);
          border-color: rgba(var(--vs-primary), 0.1);
        }
      }
    }
    .table_theme {
      .el-table__expanded-cell[class*=cell] {
        padding: 0px;
      }
      .el-table__body tr{
        &:hover {
          cursor: pointer;
        }
      }
    }
    .table_height_container {
      display: flex;
      flex-direction: column;
      min-height: calc(100vh - 200px);
    }
    .el-button.is-disabled.el-button--text {
      text-decoration: line-through;
    }
    .cardListDrawer {
      top: 60px;
      .el-drawer__header {
        margin-bottom: 0px;
      }
      .box-card {
        margin-bottom: 10px;
        .item {
          position: relative;
          height: 100px;
          .bottom {
            position: absolute;
            bottom: 0;
            right: 0;
          }
        }
      }
    }
  }
</style>